<template>
  <CommingSoon />
</template>

<script setup lang="ts">
import CommingSoon from "~/components/common/CommingSoon.vue";
import { useNavbarInfo } from "~/stores/useNabvarInfo";

const navbar = useNavbarInfo();
navbar.changeInfo("Dashboard", []);

useHead({
  title: "Dashboard",
});
</script>
